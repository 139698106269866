import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const BioFuels = () => {
    return (
        <Layout>
            <Seo title="Bio Fuels" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Bio Fuels</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6 d-flex justify-content-center ">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/norway.jpg"
                                    aspectRatio={16 / 6}
                                    width={696}
                                    layout="fixed"
                                />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="card-body h-100 services-text d-flex flex-column">
                                    <p>
                                        Delta Energy alongside their sister company Delta Shipping Corp have an important objective to
                                        remain forward-thinking with regards to all future regulation changes and stay in touch with
                                        current geopolitical and environmental issues. Whilst we will lower Delta Shipping Corp’ GHG
                                        Emissions by providing new environmentally friendlier bunker fuels, we want to do more. The
                                        transition this year will see us work closely with European and Worldwide governments and
                                        adhere to their objectives to bring down GHG emissions in the shipping and barge sector.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <p>
                            We have been working for some time now to bring new BioFuels feedstocks to the ARA market and hope to roll
                            this out globally, very soon. We have an exclusive and trusted partner that shares our vision to develop
                            this side of the business with us. Our aim with the product we have ready is to firstly service our client
                            base with a BioFuels Bunker product which will significantly reduce vessels GHG.
                        </p>
                        <p>
                            Further, we will continue to ensure that the product remains within latest ISO specifications and remains
                            competitive to traditional bunker fuels. With these steps taken after numerous analysis and compatibility
                            tests we are very happy with the results and in these times when shipowners and operators are having to
                            invest for future vessels in infrastructure and equipment, we have found a cost-effective solution which
                            does not require any modifications on board of a vessel. Taking these fuels remains the same process as
                            taking a traditional bunker product.
                        </p>
                        <p>
                            As well as the above, we are always looking for opportunities within this stricter low-carbon trading
                            environment and we understand the pressure coming from the European Governments and the shipping industry
                            to do better and be better in this regard. With this in mind, we will continue with our partners to remain
                            innovative.
                        </p>
                        <p>
                            Delta Energy can also confirm we are registered & certified by all governing bodies in order to supply the
                            BioFuels Products mentioned above.
                        </p>
                        <p>
                            Delta Energy Fuel Supply & Trading BV has been granted the international sustainability and carbon
                            certification. Delta Energy are a proud member of ISCC and are dedicated to help reduce carbon footprint
                            throughout the supply chain and their own GHG savings. Looking towards a sustainable future in the oil and
                            shipping markets
                        </p>
                        <StaticImage
                            src="../../../images/other/certificate-iscc.png"
                            width={250}
                            alt="ISCC certified"
                            placeholder="tracedSVG"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BioFuels
